<template>
  <div>
    Loading...
  </div>
</template>

<script>
export default {
  created() {
    alert("1");
  },
};
</script>

<style lang="scss" scoped>
div {
  transform: scale(0.6);
  animation: loading 1s infinite linear;
  background: lime;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
}
@keyframes loading {
  from {
    transform: scale(0.6);
  }
  to {
    transform: scale(1.5);
  }
}
</style>
