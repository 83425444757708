<template>
  <div class="home">
    <h1 class="p-4 font-bold text-3xl text-center">Home</h1>
    <hr />
    <StyleComp :colorProp="`red`"></StyleComp>
    <hr />
    <br />

    <template v-if="events">
      <div class="events max-w-5xl mx-auto">
        <div v-for="event in events" :key="event.id">
          {{ event.title }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import StyleComp from "@/components/1/Style.vue";
import { ref } from "@vue/reactivity";
import dataFetch from "@/composables/getEvents.js";

export default {
  name: "Home",
  components: {
    StyleComp,
  },
  setup() {
    const events = ref(null);
    const { getData } = dataFetch();
    const getDataFn = async () => {
      events.value = await getData();
    };
    getDataFn();

    return {
      events,
    };
  },
};
</script>
