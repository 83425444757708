import axios from "axios";
import { ref } from "vue";

export default function dataFetch() {
  const data = ref([]);

  const getData = async () => {
    const res = await axios.get(
      "https://my-json-server.typicode.com/possibility-of-offense/defineAsyncComponent/events"
    );
    data.value = res.data;
    return data.value;
  };

  return {
    getData,
  };
}
