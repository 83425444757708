<template>
  <div class="pre-hiding">{{ testData }}</div>
  <header
    class="main-menu bg-gradient-to-r from-indigo-200 to-gray-500 items-center text-2xl text-white p-3 .shadow-lg"
  >
    <h3 class="heading-main tracking-wide text-gray-500	italic text-4xl">
      <img src="./assets/logo.png" class="w-12" />
    </h3>
    <router-link
      class="bg-white text-indigo-200 rounded-md px-4 py-1 text-center flex justify-center "
      to="/"
      >Home</router-link
    >
  </header>
  <template v-if="!showSuspense">
    <div v-if="err">{{ err }}</div>

    <Suspense v-else>
      <AsyncComp />
    </Suspense>
  </template>

  <router-view></router-view>
</template>

<script>
import {
  defineAsyncComponent,
  getCurrentInstance,
  onErrorCaptured,
  onMounted,
  ref,
} from "vue";
import LoadingComponent from "@/components/Loading.vue";
import ErrorComponent from "@/components/ErrorComp.vue";

export default {
  name: "App",
  components: {
    LoadingComponent,
    ErrorComponent,
    // AsyncComp: defineAsyncComponent(() => import("@/components/Async.vue")),
    AsyncComp: defineAsyncComponent({
      loader: () => import("@/components/Async.vue"),
      loadingComponent: LoadingComponent,
      errorComponent: ErrorComponent,
      delay: 2000, // default: 200
      timeout: 6000,
      suspensible: false, // default: true
    }),
  },
  created() {
    setTimeout(() => {
      this.$forceUpdate();
      document.querySelector(".pre-hiding")?.classList.add("post-hiding");
    }, 2000);
  },
  methods: {
    forceUpdateSetup() {
      this.testData = "Force updating";
      document.querySelector(".heading-main").style.textAlign = "center";
      this.$forceUpdate();
    },
  },
  setup(props, context) {
    const instance = getCurrentInstance();
    const err = ref("");
    const showSuspense = ref(false);

    onMounted(() => {
      const getForceUpdateSetup = instance.ctx.forceUpdateSetup();
    });

    onErrorCaptured((error) => {
      err.value = `error ${error}`;
    });

    return {
      err,
      showSuspense,
    };
  },
};
</script>

<style lang="scss">
body {
  background-color: #e0e7ff98;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.post-hiding {
  animation: hiding 1s forwards;
}
@keyframes hiding {
  from {
    opacity: 1;
    height: auto;
  }
  to {
    opacity: 0;
    height: 0;
  }
}

.main-menu {
  display: flex;
  & > * {
    margin: 0 10px;
  }
}
</style>
