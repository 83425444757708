<template>
  <div>
    <h2 :style="{ color: colorProp }">Style Heading</h2>
  </div>
</template>

<script>
export default {
  props: ["colorProp"],
};
</script>

<style lang="scss" scoped></style>
